import { cn } from '@trustblock/helpers/classname';
import Link from 'next/link';
import type React from 'react';
import Icon, { type IconName } from '../Icon/Icon';
import Loading from '../Loading/Loading';

interface ButtonProps {
  icon?: IconName;
  mode?: 'primary' | 'secondary' | 'ternary' | 'outline';
  fill?: boolean;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'link';
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  loading?: boolean;
  iconPosition?: 'left' | 'right';
  href?: React.ComponentProps<typeof Link>['href'];
  target?: string;
  onlyIcon?: boolean;
  active?: boolean;
  noColorSwitch?: boolean;
  noTransform?: boolean;
  className?: string;
  iconClassName?: string;
  rel?: string;
  style?: React.CSSProperties;
}

const baseClassname =
  'inline-flex items-center justify-center rounded-md relative box-border transition-all ease-out duration-2 hover:transform px-12 h-9 text-body font-semibold';

const innerDivBaseClassname =
  'flex items-center justify-center gap-2 transition-all duration-2 ease-out whitespace-nowrap';

function Button({
  mode = 'secondary',
  fill = false,
  children,
  type = 'button',
  disabled = false,
  onClick,
  icon,
  loading = false,
  iconPosition = 'left',
  href,
  target,
  onlyIcon = false,
  active = false,
  noColorSwitch = false,
  noTransform = false,
  className,
  iconClassName,
  style,
  ...props
}: ButtonProps) {
  const modeClassname = cn({
    'bg-primary text-white': mode === 'primary',
    'bg-black80 text-white': mode === 'secondary',
    'bg-white101 text-black80': mode === 'ternary',
    'border border-solid border-black100 text-black100 bg-transparent [&>*>svg]:text-black100': mode === 'outline',
    'enabled:hover:bg-white102': mode === 'ternary' && type !== 'link' && !noColorSwitch,
    'enabled:hover:bg-purple110': mode === 'primary' && type !== 'link' && !noColorSwitch,
    'enabled:hover:bg-black100': mode === 'secondary' && type !== 'link' && !noColorSwitch,
    'enabled:hover:border-purple110 enabled:hover:text-purple110':
      mode === 'outline' && type !== 'link' && !noColorSwitch,
    'hover:bg-purple110': mode === 'primary' && type === 'link' && !noColorSwitch,
    'hover:bg-black100': mode === 'secondary' && type === 'link' && !noColorSwitch,
    'hover:bg-white102': mode === 'ternary' && type === 'link' && !noColorSwitch,
    'hover:border-purple110 enabled:hover:text-purple110': mode === 'outline' && type === 'link' && !noColorSwitch
  });

  const finalClassName = cn(
    baseClassname,
    modeClassname,
    {
      'w-full': fill,
      'cursor-not-allowed opacity-70': loading || disabled,
      'px-8': !onlyIcon && (icon ?? loading),
      'px-3': onlyIcon,
      group: disabled === false,
      'lg:hover:scale-x-[1.10]': disabled === false && !fill && !loading && !active && !noTransform,
      'scale-x-[1.10]': active
    },
    className
  );

  const defaultContent = (
    <>
      {loading && (
        <Loading
          mode="button"
          size="small"
          className={cn({
            'stroke-black100': mode === 'outline',
            'group-hover:stroke-primary': mode === 'outline' && !noColorSwitch,
            'stroke-white90': mode !== 'outline',
            'stroke-black80': mode === 'ternary'
          })}
        />
      )}
      {icon && !loading && (
        <Icon
          name={icon}
          className={cn(
            'size-4 transition-colors duration-3 ease-out stroke-[2px]',
            {
              'group-hover:text-primary': mode === 'outline' && !noColorSwitch,
              'text-[color:var(--icon-color,#fff)]': mode !== 'outline',
              'stroke-black100': mode === 'outline',
              'group-hover:stroke-primary': mode === 'outline' && !noColorSwitch,
              'stroke-white90': mode === 'primary' || mode === 'secondary',
              'stroke-black80': mode === 'ternary'
            },
            iconClassName
          )}
        />
      )}
    </>
  );

  if (type === 'submit' || type === 'button') {
    return (
      <button className={finalClassName} disabled={disabled || loading} type={type} onClick={onClick} {...props}>
        <div
          className={cn(innerDivBaseClassname, {
            'group-hover:scale-[0.90]': !active && !disabled && !loading && fill && !noTransform,
            'lg:group-hover:scale-x-[0.88]': disabled === false && !fill && !loading && !active && !noTransform,
            'scale-x-[0.88]': active
          })}
        >
          {iconPosition === 'left' && defaultContent}
          {children}
          {iconPosition === 'right' && defaultContent}
        </div>
      </button>
    );
  }

  return (
    <Link
      href={href ?? ''}
      target={target}
      className={cn(
        'group',
        {
          'hover:text-primary': !disabled && mode === 'outline' && !noColorSwitch
        },
        finalClassName
      )}
      onClick={onClick}
      {...props}
    >
      <div
        className={cn(innerDivBaseClassname, {
          'group-hover:scale-[0.90]': !active && !disabled && !loading && fill && !noTransform,
          'lg:group-hover:scale-x-[0.88]': disabled === false && !fill && !loading && !active && !noTransform,
          'scale-x-[0.88]': active
        })}
      >
        {defaultContent}
        {children}
      </div>
    </Link>
  );
}

export default Button;
